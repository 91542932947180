import React from 'react';
import './App.css';
import logo from './assets/logo.png';

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img src={logo} alt="Dreamstrl Logo" />
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>We make some changes.</h1>
            <p>We’re performing some updates to improve your experience. The site will be back online soon – thank you for your patience!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
